* {
  box-sizing: border-box;
}

.app {
  margin: 0 auto;
  max-width: 1000px;
  font-family: 'Poppins', Tahoma, Arial;
  font-size: 1rem;
  padding: 1rem;

  & .header {
    display: flex;
    gap: 1rem;
    align-items: center;
    flex-wrap: wrap;
    & .logo {
      flex: 1;
      font-weight: 800;
      cursor: pointer;
    }
    & .contact-info {
      display: flex;
      gap: 0.5rem;
      align-items: center;
      flex-wrap: wrap;
      & .contact-info-item {
        display: flex;
        gap: 0.5rem;
        align-items: center;
        & .contact-info-icon {
          width: 1.5rem;
        }
      }
    }
    & .fb-link {
      align-items: center;
      display: flex;
      & img {
        width: 1.5rem;
      }
    }
  }
  & .form {
    margin-top: 2rem;
    display: flex;
    flex-direction: column;
    gap: 1rem;
    & h3 {
      margin: 0;
      padding: 0;
    }
    & h4 {
      margin: 0 0 1rem;
      padding: 0;
    }
    & label {
      width: 100%;
      padding-bottom: 0.3rem;
      display: inline-block;
    }
    & input {
      background: #f6f6f6;
      xbackground: #f7f6f1;
      border: 1px solid #ccc;
      xborder: 1px solid #dad8d1;
      padding: 0.5rem;
      width: 100%;
      border-radius: 0.25rem;
      font-size: 1rem;
    }
  }
  & .line-100 {
    width: 100%;
  }
  & .inpost {
    margin-top: 1rem;
    & label {
      padding-bottom: 0.3rem;
      display: inline-block;
      width: 100%;
    }
  }
  & .products {
    display: flex;
    gap: 3rem;
    margin-top: 2rem;
    flex-wrap: wrap;
    & .product {
      display: flex;
      flex-direction: column;
      gap: 0.5rem;
      flex: 1;
      min-width: 300px;
      max-width: 450px;
      & .product-name {
        font-weight: 800;
      }
      & .product-desc {
        white-space: pre-wrap;
      }
      & .product-image {
        position: relative;
        & img {
          max-width: 450px;
          height: 400px;
          width: 100%;
          object-fit: cover;
          border-radius: 0.5rem;
        }
      }
      & .product-image-small {
        & img {
          height: auto;
        }
      }
      & .product-image-zoom {
        color: #000;
        position: absolute;
        right: 1rem;
        bottom: 1rem;
        text-decoration: none;
        background: #fff;
        padding: 0.5rem;
        border-radius: 0.5rem;
        cursor: pointer;
      }
      & .product-images {
        display: flex;
        gap: 0.6rem;
        & img {
          max-width: 100px;
          height: 100px;
          width: 100%;
          object-fit: cover;
          position: relative;
          cursor: pointer;
          border-radius: 0.5rem;
        }
      }
      & .button-basic {
        margin-top: 0.5rem;
        text-decoration: none;
      }
      & .product-quantity {
        width: 100%;
        font-weight: 600;
        text-align: right;
      }
      & .product-button {
        gap: 0 1rem;
        display: flex;
        flex-wrap: wrap;
        justify-content: end;
      }
    }
  }

  & .cart-products {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    & .product {
      display: flex;
      gap: 1rem;
      align-items: center;
      & .product-name {
        flex: 1;
      }
    }
    & .cart-total {
      border-top: 1px solid #ccc;
      font-weight: 600;
      padding: 1rem 0;
    }
  }

  & .footer {
    gap: 1rem;
    padding: 3rem 0 0.5rem;
    display: flex;
    align-items: center;
  }
  & .copyright {
    padding: 0;
  }

  & .a-basic {
    color: #333;
    text-decoration: none;
  }

  & .info-confirmation {
    padding: 30vh 10vw;
    font-size: 1.2rem;
    font-weight: 600;
    min-height: 300px;
  }
}

#inpost-map-container {
  position: fixed;
  z-index: 9999;
  top: 0;
  left: -100vw;
  width: 100vw;
  height: 100vh;
  background: #fff;
}

.inpost-map-hidden {
  z-index: 0;
  top: -100px;
  left: 0;
  position: absolute;
  width: 1px;
  height: 1px;
  overflow: hidden;
}

.button-basic {
  padding: 0.8rem;
  color: #fff;
  background: #42413f;
  border: 1px solid #343434;
  border-radius: 0.5rem;
  cursor: pointer;
  font-size: 1rem;
  font-weight: 600;
}

.button-third {
  color: #333;
  background: #ffffff;
  border: 1px solid #bcb9b9;
  font-size: 0.8rem;
  padding: 0.3rem;
  min-width: 1.5rem;
  text-align: center;
}

.button-secondary {
  color: #333;
  background: #d4d3d1;
  border: 1px solid #7e7c7c;

  color: #333;
  background: #ffffff;
  border: 1px solid #bcb9b9;
  xfont-size: 0.8rem;
  xpadding: 0.3rem;
  min-width: 1.5rem;
  text-align: center;
}

.button-final {
  padding: 1rem 3rem;
  color: #fff;
  background: rgb(2, 153, 2);
  border: none;
  border-radius: 0.5rem;
  cursor: pointer;
  font-size: 1rem;
  font-weight: 600;
  text-transform: uppercase;
}

.final-actions {
  padding: 1rem 0 3rem;
}

.loading {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  font-weight: 800;
}

@media only screen and (max-width: 600px),
  @media only screen and (max-width: 1300px) and (-webkit-device-pixel-ratio: 3),
  @media only screen and (max-width: 900px) and (-webkit-device-pixel-ratio: 2) {
  .app {
    & .header {
      gap: 0.5rem;
    }
    & .footer {
      display: flex;
      flex-direction: column;
      align-items: start;
      padding: 1rem 0;
    }
    & .products {
      gap: 2rem;
      padding-bottom: 3rem;
    }
  }
}
